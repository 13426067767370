var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-bottom":"200px"}},[_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-4"},[_c('div',{staticClass:"col-4 col-md-4"},[_c('base-input',{attrs:{"type":"search","prepend-icon":"fas fa-search","placeholder":_vm.$t('COMMON.SEARCH'),"clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('div',{staticClass:"col-4 col-md-4"},[_c('base-input',[_c('category-group-selector',{attrs:{"allowNone":true,"showAll":false,"isOnlyModels":false,"placeholder":_vm.$t('COMMON.CATEGORIES')},on:{"categoryChanged":(categoryId) => {
                _vm.selectedCategory = categoryId;
              }}})],1)],1),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SUPPLIERS))?_c('div',{staticClass:"col-4 col-md-4"},[_c('base-input',[_c('supplier-selector',{attrs:{"filterOrganization":_vm.stockMovement.organization.id,"filterWarehouseBuyingPriceNotNull":_vm.getWarehouse()},on:{"supplierChanged":(supplierId) => (_vm.selectedSupplier = supplierId)}})],1)],1):_vm._e()]),_c('div',{ref:"triggerElement",staticStyle:{"width":"100%","height":"1px"}}),_c('el-table',{ref:"stickyDiv",staticClass:"table-responsive align-items-center table-flush stock-movement-product-list",class:{ sticky: _vm.isSticky },attrs:{"header-row-class-name":"thead-light","data":_vm.filteredStockMovementItemsModel,"type":"expand"}},[(_vm.loading || !_vm.stockMovement.id)?_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('img',{staticStyle:{"height":"100px","width":"100px"},attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.PRODUCT'),"prop":"product.name","min-width":"400px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.id < 0)?_c('base-input',[_c('product-selector',{attrs:{"filterOrganization":_vm.stockMovement.organization.id,"product":_vm.stockMovementItemsModel[row.id].storable.id,"filterWarehouse":_vm.getWarehouse(),"allowNone":false,"showAll":false,"disabled":row.id > 0,"filterIdsNotIn":_vm.existingProducts},on:{"productChanged":(productId, product) => {
                  _vm.stockMovementItemsModel[row.id].storable = product;
                  _vm.updateStockMovementItemDebounced(row.id);
                }}})],1):_vm._e(),(row.storable && row.id > 0)?_c('div',[_c('object-link',{attrs:{"object":row.storable}}),_c('br'),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(row.storable.categoriesNames)),_c('br'),_vm._v(" "+_vm._s(row.storable.sku)+" ")])],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('STOCK_MOVEMENTS.ORDER_UNIT_OF_MEASURE'),"prop":"quantity","width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(
              _vm.stockMovementItemsModel[row.id]?.storable.orderUnitOfMeasureUnit
            )?_c('object-link',{attrs:{"object":_vm.stockMovementItemsModel[row.id].storable.orderUnitOfMeasureUnit}}):_vm._e()]}}])}),(!!_vm.stockMovement || _vm.stockMovement.movement_type == _vm.TYPE_CORRECT)?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.WAREHOUSE_QUANTITY'),"prop":"quantity","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',[_vm._v(" "+_vm._s(_vm.stockMovementItemsModel[row.id].warehouse_quantity)+" ")])]}}],null,false,3195470538)}):_vm._e(),_c('el-table-column',{attrs:{"label":`${
          _vm.stockMovement.movement_type == _vm.TYPE_CORRECT
            ? _vm.$t('COMMON.AJUSTED_QUANTITY')
            : _vm.$t('COMMON.QUANTITY')
        }`,"prop":"quantity","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(
              _vm.stockMovement.status == _vm.STATUS_DRAFT &&
              _vm.stockMovementItemsModel[row.id]
            )?_c('base-input',{staticClass:"my-5",attrs:{"placeholder":_vm.$t('COMMON.QUANTITY'),"type":"number","step":"0.01","input-classes":"form-control-alternative"},on:{"change":function($event){return _vm.updateStockMovementItemDebounced(row.id)}},model:{value:(_vm.stockMovementItemsModel[row.id].quantity),callback:function ($$v) {_vm.$set(_vm.stockMovementItemsModel[row.id], "quantity", $$v)},expression:"stockMovementItemsModel[row.id].quantity"}}):_vm._e(),(_vm.stockMovement.status != _vm.STATUS_DRAFT)?_c('span',[_vm._v(" "+_vm._s(_vm.stockMovementItemsModel[row.id].quantity)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('STOCK_MOVEMENTS.STORAGE_UNIT'),"prop":"quantity","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(
              _vm.stockMovement.status == _vm.STATUS_DRAFT &&
              !_vm.stockMovementItemsModel[row.id]?.unitOfMeasureEdit
            )?_c('i',{staticClass:"fa fa-pencil-alt mr-1",staticStyle:{"cursor":"pointer"},on:{"click":() => {
                _vm.stockMovementItemsModel[row.id].unitOfMeasureEdit = true;
              }}}):_vm._e(),(
              _vm.stockMovement.status == _vm.STATUS_DRAFT &&
              _vm.stockMovementItemsModel[row.id]?.unitOfMeasureId &&
              _vm.stockMovementItemsModel[row.id]?.unitOfMeasureEdit
            )?_c('unit-of-measure-unit-selector',{attrs:{"filterOrganization":_vm.stockMovement.organization.id,"filterUnitOfMeasure":_vm.stockMovementItemsModel[row.id].unitOfMeasureId,"unit":_vm.stockMovementItemsModel[row.id]?.unitOfMeasureUnit?.id,"allowNone":false,"showAll":false},on:{"unitChanged":(unit) => {
                _vm.stockMovementItemsModel[row.id].unitOfMeasureUnit = unit;
                _vm.updateStockMovementItemDebounced(row.id);
              }}}):_vm._e(),(
              _vm.stockMovementItemsModel[row.id]?.unitOfMeasureId &&
              !_vm.stockMovementItemsModel[row.id]?.unitOfMeasureEdit
            )?_c('object-link',{attrs:{"object":_vm.stockMovementItemsModel[row.id].unitOfMeasureUnit}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.BUYING_PRICE'),"prop":"buying_price","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(_vm.stockMovementItemsModel[row.id]?.storable.buying_price)?_c('span',[_vm._v(" "+_vm._s(_vm.$formatCurrency( _vm.stockMovementItemsModel[row.id].buying_unit_price ))+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.TOTAL_VALUE'),"prop":"buying_price","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(_vm.stockMovementItemsModel[row.id]?.storable.buying_price)?_c('span',[_vm._v(" "+_vm._s(_vm.$formatCurrency( _vm.stockMovementItemsModel[row.id].buying_total_value ))+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{staticClass:"table-actions"},[(
              _vm.stockMovementItemsModel[row.id] &&
              _vm.stockMovementItemsModel[row.id].loading
            )?_c('el-tooltip',{attrs:{"content":"Loading","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",attrs:{"type":"text","data-toggle":"tooltip"}},[_c('i',{staticClass:"fas fa-spinner fa-spin"})])]):_vm._e(),(
              _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_PRODUCTS) &&
              _vm.stockMovement.status == _vm.STATUS_DRAFT
            )?_c('el-tooltip',{attrs:{"content":"Delete","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deleteStockMovementItem(row)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()],1)}}])})],1),(_vm.stockMovement.status == _vm.STATUS_DRAFT)?_c('div',{staticClass:"row px-3 mt-3"},[(_vm.stockMovement.movement_type != _vm.TYPE_CORRECT)?_c('base-button',{staticClass:"btn-sm mr-1 col-12",staticStyle:{"width":"100%"},attrs:{"type":"info"},on:{"click":() => {
            _vm.addLine();
          }}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("PRODUCTS.ADD_PRODUCT"))+" ")]):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"stock-movement-summary"},[_c('table',[_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("COMMON.TOTAL_VALUE")))]),_c('td',{staticClass:"total",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$formatCurrency(_vm.getTotalValue()))+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }